import ApexCharts from 'apexcharts';
export class DonutChart {
    public option = {};
    public element: string;
    public chartInstance: ApexCharts;
    public buildOptions(data: any) {
        return {
            chart: {
                type: "donut",
                fontFamily: "Conv_Montserrat-Regular",
                height: 270
            },
            stroke: {
                show: true,
                curve: "smooth",
                lineCap: "butt",
                // colors: "#1b1c21",
                width: 0,
                dashArray: 0
            },
            ...data,
            legend: false,
            plotOptions: {
                pie: {
                    customScale: 1,
                    offsetX: 0,
                    offsetY: 0,
                    expandOnClick: true,
                    dataLabels: {
                        offset: 0,
                        minAngleToShowLabel: 10
                    },
                    donut: {
                        size: '80%',
                        labels: {
                            show: true,
                            background: "transparent",
                            
                            value: {
                                show: true,
                                fontSize: "20px",
                                color: "white",
                                formatter: function (val) {
                                    return val;
                                }
                            },
                            name: {
                                show: true,
                                fontSize: "12px",
                                color: "white",
                            },
                        },
                    }
                }
            },
            dataLabels: {
                formatter: function (val, opts) {
                    return opts.w.config.series[opts.seriesIndex];
                }
            },
            responsive: [
                {
                    breakpoint: 1200,
                    options: {
                        chart: {
                            width: 200,
                            height: 230,
                            marginRight: 15
                        }
                    }
                },

                {
                    breakpoint: 991,
                    options: {
                        chart: {
                            width: 250,
                            height: 270,
                            marginRight: 15
                        }
                    }
                },

                {
                    breakpoint: 575,
                    options: {
                        chart: {
                            width: 200,
                            height: 220,
                            marginRight: 15
                        }
                    }
                }
            ]
        }
    }

    constructor(data: any, elem: string) {
        this.element = elem;
        this.option = this.buildOptions(data);
        this.renderDefault()
    }

    renderDefault() {
        this.chartInstance = new ApexCharts(
            document.querySelector(`#${this.element}`),
            this.option
        );
        this.chartInstance.render();
    }

    updateDonutChart(data: any) {
        this.chartInstance.updateOptions(this.buildOptions(data))
    }
}