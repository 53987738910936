


import { Component, Vue } from "vue-property-decorator";
import { BASE_API_URL, PHONE_EXT } from "@/config";
import Axios from "axios";
import _ from "lodash";

import { DonutChart } from "@/views/charts/donut-chart/donut-chart";
import { Device } from "twilio-client";
import { mixins } from "vue-class-component";
import CommonMixin from "../../../mixins/common.mixin";
import { HollowDotsSpinner } from "epic-spinners";
import striptags from "striptags";
import moment from "moment";
import DisplayFiles from "@/views/DisplayDocument.vue";
import Multiselect from "vue-multiselect";
import stores from "@/store/store";
import Datepicker from "vuejs-datepicker";
import UpdateClosingAndRateDate from "@/views/updateClosingAndRateLockDate.vue";
import { CustomTask } from "@/models/tasks.model";
import { ALLOWED_FILE_EXTENSIONS, SORTING_TYPES } from "@/constants";

const store: any = stores;
@Component({
  components: {
    HollowDotsSpinner,
    DisplayFiles,
    Multiselect,
    Datepicker,
    UpdateClosingAndRateDate
  }
})
export default class DashboardComponent extends mixins(CommonMixin) {
  public allowedFileExtensions = ALLOWED_FILE_EXTENSIONS;
  public donutChart: DonutChart;
  public loadMoreScroll = false;
  public hideLoadMore: Boolean = null;
  public customTaskData = new CustomTask();
  public loanReports = {
    loansClosed: 0,
    advancedStatus: 0,
    communications: 0,
    newLoans: 0
  };
  public firstTimeLoanReport: Boolean = null;
  public name = "";
  public borrowers: any = [];
  public borrowerLoader = false;
  public loadMore = false;
  public previousDate = "";
  public loanStatus = null;
  public searchString = null;
  public borrowerList: any = [];
  public borrowerCommunication: any = {};
  public playingAudioIndex = null;
  public finalPlayingAudioIndex = null;
  public basis = "Date";
  public messageText = "";
  public displayingMessageNumber = "";
  public disableMessage = false;
  public inProcess = false;
  public communicationdotLoader = false;
  public callingStatus = "Connecting...";
  public callInProgress = false;
  // Sorting variables
  public selectedSorting = ''; 
  public sortingTypes = SORTING_TYPES;
  public sortDESC = false;
  //=============================================================
  //Communication related variables
  public communicationId = null;
  public communicationNumber = null;
  public communicationLoanTxnId = null;
  public communicationUserId = null;
  public communicationType = null;
  public communicationBody = null;
  public communicationRecording = null;
  public audioPlaying = false;
  public communicationDate = null;
  public communicationUserType = null;
  public isReplied = null;
  public dotLoader = null;
  public comment = "";
  public review = false;
  public reviewDoc: any = null;
  public reClassify = null;
  public commentSampleAttachment = [];
  public documents: any = null;
  public comments: any = [];
  public props = {};
  public functionName = "setInitialValues";
  public currentReviewLoanId = "";
  public currentNotificationId = "";
  public currentFileCategory: any = null;
  //=============================================================
  public wemloStaffList = [];
  public wemloJuniorProcessor: any = [];
  public loanNotificationLoader = false;
  public hasChartData = false;
  public funding = false;
  public customTaskDetail = null;
  public files = [];
  public countFile = null;
  //custom Task data
  public customTask = {
    taskName: null,
    type: null,
    teamMember: null,
    borrower: null,
    displayEventToBorrower: false,
    selectDate: null,
    time: null,
    reminder: null,
    description: null
  };
  public time = {
    hour: null,
    dayNight: "AM",
    minutes: null
  };
  public state: any = {};
  public customDoc = [];
  public notificationShower = {
    loanTxnId: null,
    compensationType: null,
    compensation: null,
    closingDate: null,
    rateType: null,
    waive: null,
    matchLenderToBpc: null,
    description: null
  };
  public taskOption: any = {
    lockType: false,
    rateLockDate: null,
    updatedRateExpirationDate: null,
    extendedDate: null,
    loanTxnId: null,
    extensionType: false,
    closingDate: null
  };
  public showLockRateDate = false;
  public attachment = [];
  public reportToInfo = [];
  public searchLoanProcessors = "All";
  public staffData = null;
  public searchLoanString = "";
  public pipeLineData = [];
  public pipeLineDataSearchAll = [];
  public selectedProcessor: object = null;
  public tasks = [];
  public loanTxnId = "";
  public completedByName = "";
  public disabledButton = false;
  public allBorrowers = [];
  public borrowerName = [];
  public reportees = [];
  public closingError = false;
  public customId = null;
  public selectedPipelineProcessorId = null;
  public initialPagination = { start: 0, end: 15 };
  public pagination: any = JSON.parse(JSON.stringify(this.initialPagination));
  public inProcessing = false;
  public advancedStatusLoader = false;
  public wemloNumber = null;
  public commModuleId = null;

  //Exception Reports Declaration--------------------------------------------------------------------
  public reportLoader: Boolean = true;
  public currentReportTab: String = null;
  public reports: Array<any> = [];
  public reportCategoryCount = {
    critical: 0,
    warning: 0
  };
  public clearReportData = {
    password: null,
    reportId: null,
    clearComment: null
  };
  public reportCounts: any = {
    task: null,
    communication: null,
    closing: null
  };
  public clearReportLoader: Boolean = null;
  public replyCommunicationData = new replyCommunicationData();
  public replyCommunicationMessage = new replyCommunicationMessage();
  public replyCommunicationLoader = {
    text: false,
    message: false
  };
  public notificatioModuleId = null;
  public file = [];
  public closingDate = null;
  public editType = null;
  public page = 0;
  public currentBorrowerName = null;
  public reportCategoryLoader = false;
  //**********************************************
  public loanAudit = {
    loanTxnId: null,
    source: "Processor Dashboard",
    updateOn: new Date(),
    updatedBy: this.$userId,
    activity: {
      closingDate: null,
      rateType: null,
      rateLockExpirationDate: null,
      rateLockDate: null,
      extendedDate: null
    }
  };
  public showClosingandRateDate = false;
  public updateDatesObj = null;
  private processorsHistory = null;

  //**********************************************

  //-------------------------------------------------------------------------------------------------
  get processorName() {
    return (
      this.$store.state.sessionObject.userInfo.firstName +
      " " +
      this.$store.state.sessionObject.userInfo.lastName
    );
  }

  public getProcessorName(processor) {
    return _.get(processor, 'firstName') + ' ' + _.get(processor, 'lastName');
  }

  public getCustomTaskProcessors(task, processorOfRecord) {
    const members = _.get(task, 'teamMember', []);
    const names = members.map(member => _.get(member, 'name', ''));
    const isProcessorOfRecordAMember = !!members.find(member => _.get(member, 'id', null) == _.get(processorOfRecord, 'userId'));

    if (isProcessorOfRecordAMember && names.length <= 1) {
      return '';
    }

    return names.join('\n');
  }

  haveToggleOpen(index) {
    return this.borrowerCommunication["borrower" + index];
  }

  get showLoader() {
    let currentTabCount = 0;
    switch (this.currentReportTab) {
      case "Task":
        currentTabCount = this.reportCounts["task"];
        break;
      case "Communication":
        currentTabCount = this.reportCounts["communication"];
        break;
      default:
        currentTabCount = this.reportCounts["closing"];
        break;
    }
    return this.reports.length > 0
      ? this.reports.length < currentTabCount
      : false;
  }

  public async playAudio(index, event) {
    this.audioPlaying = true;
  }

  public stopPlayingAudio(index, event) {
    this.audioPlaying = false;
  }

  public get processorType() {
    return this.$store.state.sessionObject.userInfo.position;
  }
  public get processorId() {
    return this.$store.state.sessionObject.userId;
  }

  public async toggleBorrowerCommunication(
    loanTxnId,
    borrowerUserId,
    processorId,
    i,
    borrowerName
  ) {
    this.currentBorrowerName = borrowerName;
    try {
      this.loanNotificationLoader = true;

      if (!this.borrowerCommunication["borrower" + i])
        this.borrowerCommunication = { ["borrower" + i]: false };
      this.borrowerCommunication = JSON.parse(
        JSON.stringify(this.borrowerCommunication)
      );

      let response = await Axios.post(
        BASE_API_URL + "wemloprocessor/getLoanNotifications",
        {
          loanTxnId: loanTxnId,
          borrowerUserId: borrowerUserId,
          userId: this.$userId,
          assignProcessorId: processorId
        });
      this.pipeLineData.forEach(element => {
        if (element.userId.toString() == borrowerUserId.toString()) {
          element.communication = response.data;
          element.notificationCount = response.data.length;
        } else {
          element.communication = [];
        }
      });
      this.borrowerCommunication["borrower" + i] = !this.borrowerCommunication[
        "borrower" + i
      ];

      this.loanNotificationLoader = false;
    } catch (error) {
      console.log(error);
    }
  }

  public async changeNotificationisReadStatus(notificationId, loanTxnId) {
    try {
      this.pipeLineData.forEach(element => {
        if (element.loanTxnId == loanTxnId) {
          element.notifications--;
          element.communication = element.communication.filter(
            e => e._id != notificationId
          );
        }
      });

      this.pipeLineData = JSON.parse(JSON.stringify(this.pipeLineData));
      let unReadedCommunications = [notificationId];
      let response = await Axios.post(
        BASE_API_URL + "communication/changeNotificationisReadStatus",
        {
          unReadedCommunications: unReadedCommunications
        });
    } catch (error) {
      console.log(error.data);
    }
  }

  public timeInLocalTimeString(date) {
    let event = new Date(date);
    return event.toLocaleTimeString("en-US");
  }

  public async showMessage(type) {
    try {
      this.loanStatus = type;
      this.$modal.show("noData");
    } catch (error) {}
  }

  public async getBorrowers() {
    try {
      let searchData = [];
      if (this.pipeLineDataSearchAll.length > 0) {
        this.pipeLineDataSearchAll.forEach(data => {
          if  (typeof(this.searchString)=='string' &&
            data.borrowerName
              .toLowerCase()
              .includes(this.searchString.toLowerCase())
          ) {
            searchData.push(data);
          }
        });
        this.pipeLineData = searchData;
      }
    } catch (error) {
      console.log(error);
    }
  }

  public getPath(status, loanTxnId, data) { // NoT is Used
    if (!status) return null;
    let path = String(Vue.prototype.$getCurrentUserTypePath(status.taskUrl));
    let query = {
      id: String(loanTxnId),
      taskId: String(status.taskId)
    };
    let route: any = { path: path, query: query };
    if (status.taskId == 13) {
      if (status.hasOwnProperty("closing") && data.dates.closing) {
        if (this.checkClosingDate(data.dates.closing)) {
          route = {};
        }
      }
    }
    return route;
  }

  public checkArchievePath(data, loanTxnId) {
    let path = "/processor/borrower-detail";
    let query = { id: loanTxnId };
    let route: any = { path: path, query: query };
    if (data == true) {
      route = {};
    }
    return route;
  }

  public getClosingDate(data, type, estimated) {
    if (type == "hover") {
      let closingDate = null;
      if (data != null) {
        if (data.closing != null) {
          closingDate = moment(data.closing).format("MMMM D, YYYY");
        }
        return closingDate != null ? "Closing Date :" + " " + closingDate : "";
      }
    }
  }

  public checkClosingDate(closingDate) {
    let currentDate = new Date()
      .toJSON()
      .slice(0, 10)
      .replace(/-/g, "-");
    let closing = new Date(closingDate)
      .toJSON()
      .slice(0, 10)
      .replace(/-/g, "-");
    return closing > currentDate;
  }

  public async viewCommDetail(commId, commModuleId, borrowerId, callLog) {
    try {
      if(callLog.description && callLog.description == "Fee Worksheet approved"){
        this.$router.push({
          path: 'loan-estimate',
          query: {
            id: callLog.loanTxnId,
            review: 'true'
          }
        });
        return;
      }
      this.getWemloNumber();
      if (callLog.fromType == "Borrower") {
        if (callLog.moduleType == "wemlo message") {
          let res = await Axios.post(
            BASE_API_URL + "broker/checkUserExist",
            {
              checkId: callLog.fromUserId,
              userId: this.$userId
            });
          if (res.data.message == "User not exists") {
            this.$snotify.error(res.data.message);
            return;
          }
        }
      }

      let selectedBorrower = this.pipeLineData.find(a => a.userId == borrowerId);
      if (selectedBorrower) {
        let selectedComm = selectedBorrower.communication.find(
          a => a._id == commId
        );
        if (selectedComm) {
          this.communicationDate = selectedComm.createdOn;
          this.communicationLoanTxnId = selectedComm.loanTxnId;
          this.communicationId = selectedComm._id;
          this.commModuleId = selectedComm.moduleId;
          this.notificatioModuleId = commModuleId;
          if (selectedComm.moduleInfo == "Pending documents") {
            this.currentNotificationId = selectedComm._id;
            this.communicationType = selectedComm.moduleInfo;
            // let loader = this.$loading.show();
            this.$store.state.wemloLoader = true;
            await this.fetchDocuments(
              selectedComm.moduleId,
              selectedComm.loanTxnId
            );
            this.$store.state.wemloLoader = false;
            // loader.hide();
          } else if (selectedComm.moduleInfo == "review document") {
            this.communicationType = "Loan estimate";
            let msg = selectedComm.description;
            this.$refs.df["setInitialValuesForShowMessage"](msg);
          } else if (selectedComm.moduleInfo == "3.2") {
            this.communicationType = selectedComm.moduleInfo;
            this.notificationShower = selectedComm;
            this.$refs.df["setInitialValuesForShowMessage"](
              selectedComm.description
            );
          } else {
            this.$store.state.wemloLoader = true;
            let response = await Axios.get(
              BASE_API_URL +
                "communication/getCommunicationNotificationDetails/" +
                selectedComm.moduleId);
            this.communicationType = response.data.type;
            this.communicationUserId = response.data.fromUserId;
            this.communicationUserType = response.data.fromType;

            if (
              response.data.type == "call" ||
              response.data.type == "voiceMail"
            ) {
              this.communicationNumber = response.data.fromNumber;
              if (response.data.recordingUrl)
                this.communicationRecording = response.data.recordingUrl;
              if (response.data.type == "voiceMail") {
                if (
                  response.data.callTranscript &&
                  response.data.callTranscript != ""
                ) {
                  this.communicationBody = response.data.callTranscript;
                }
              }
            }

            if (response.data.type == "sms") {
              this.communicationBody = response.data.body;
              this.communicationNumber = response.data.fromNumber;
            }

            if (response.data.type == "wemloMessage") {
              this.communicationBody = response.data.body;
              this.attachment = response.data.attachments;
            }

            this.$store.state.wemloLoader = false;
          }
        }
      }
      if (
        this.communicationType != "Pending documents" &&
        this.communicationType != "Loan estimate" &&
        this.communicationType != "3.2"
      )
        this.$modal.show("viewCommDetail");
    } catch (error) {
      this.$store.state.wemloLoader = false;
      console.log(error);
    }
  }

  public async closeCommDetail() {
    let releaseNotification = false;
    if (this.isReplied) {
      await this.$dialog
        .confirm("Do you want to clear this notification?")
        .then(e => {
          releaseNotification = true;
        })
        .catch(f => {
          releaseNotification = false;
        });
    }

    if (releaseNotification) {
      this.changeNotificationisReadStatus(
        this.communicationId,
        this.communicationLoanTxnId
      );
    }

    this.$modal.hide("viewCommDetail");
    this.communicationNumber = null;
    this.communicationType = null;
    this.communicationBody = null;
    this.communicationRecording = null;
    this.audioPlaying = false;
    this.communicationLoanTxnId = null;
    this.communicationId = null;
    this.commModuleId = null;
    this.communicationUserId = null;
    this.message = null;
    this.communicationUserType = null;
    this.isReplied = false;
  }

  /**
   * List all the counts
   */
  public async getWemloStaffList() {
    try {
      let response = await Axios.get(
        BASE_API_URL + "wemloStaff/getWemloStaffList",
        {
          params: {
            userId: this.$userId
          }
        }
      );
      this.reportees = response.data.reportees;
      this.wemloStaffList = response.data.data;
      this.wemloJuniorProcessor = this.wemloStaffList.map(element => {
        return {
          id: element.userId,
          name: element.firstName + " " + element.lastName,
          active: false
        };
      });
    } catch (error) {
      console.log(error);
    }
  }

  public async getProcessorLoanReports(mountCall, basis: string = "Date") {
    try {
      await Promise.all([
        this.getNewLoanCount(basis),
        this.getLoanClosedCount(basis),
        this.getCommunicationCount(basis),
        this.getAdvanceStatusCount(basis)
      ]);
    } catch (error) {
      console.log(error);
    }
  }
  public async getNewLoanCount(basis) {
    try {
      this.advancedStatusLoader = true;
      let response = await Axios.get(
        BASE_API_URL + "wemloprocessor/getNewLoanCount",
        {
          params: {
            basis: basis,
            staffId: this.$userId
          }
        }
      );
      this.loanReports.newLoans = response.data.newLoans;
      this.advancedStatusLoader = false;
    } catch (error) {
      this.advancedStatusLoader = false;
      console.log(error);
    }
  }
  public async getLoanClosedCount(basis) {
    try {
      this.advancedStatusLoader = true;

      let response = await Axios.get(
        BASE_API_URL + "wemloprocessor/getLoanClosedCount",
        {
          params: {
            basis: basis,
            staffId: this.$userId
          }
        }
      );
      this.loanReports.loansClosed = response.data.loansClosed;
      this.advancedStatusLoader = false;
    } catch (error) {
      this.advancedStatusLoader = false;
      console.log(error);
    }
  }
  public async getCommunicationCount(basis) {
    try {
      this.advancedStatusLoader = true;

      let response = await Axios.get(
        BASE_API_URL + "wemloprocessor/getCommunicationCount",
        {
          params: {
            basis: basis,
            staffId: this.$userId
          }
        }
      );
      this.loanReports.communications = response.data.communications;
      this.advancedStatusLoader = false;
    } catch (error) {
      this.advancedStatusLoader = false;
      console.log(error);
    }
  }
  public async getAdvanceStatusCount(basis) {
    try {
      this.advancedStatusLoader = true;

      let response = await Axios.get(
        BASE_API_URL + "wemloprocessor/getAdvanceStatusCount",
        {
          params: {
            basis: basis,
            staffId: this.$userId
          }
        }
      );
      this.loanReports.advancedStatus = response.data.advancedStatus;
      this.advancedStatusLoader = false;
    } catch (error) {
      this.advancedStatusLoader = false;
      console.log(error);
    }
  }

  get getGlobalPipelineFilters() {
    return this.$store.state.pipelineFilters;
  }

  public async getAllStatusCount(mountCall) {
    try {
      this.advancedStatusLoader = true;
      let data: any = {
        staffId: this.$userId,
        specificProcessorId: this.searchLoanProcessors,
      };
      if (this.searchLoanProcessors != "All") {
        data.specificUser = this.searchLoanProcessors;
      }
      let response = await Axios.post(
        BASE_API_URL + "wemloprocessor/getAllStatusCount",
        data);
      if (
        !this.$lodash.isEmpty(response.data.donutChartData) &&
        response.data.donutChartData.series &&
        response.data.donutChartData.series.length > 0
      ) {
        this.hasChartData = true;
        if(mountCall){
        this.donutChart = new DonutChart(
          response.data.donutChartData,
          "donutChart"
        );
        }else{
          this.donutChart.updateDonutChart(response.data.donutChartData)
        }
      }
      this.advancedStatusLoader = false;
    } catch (error) {
      this.advancedStatusLoader = false;
      console.log(error);
    }
  }

  public async getWemloNumber() {
    let response = await Axios.post(
      BASE_API_URL + "communication/getWemloNumber",
      {
        userId: this.$store.state.sessionObject.userInfo.userId
      });
    this.wemloNumber = response.data.wemloNumber;
  }

  public async callToCustomer() {
    try {
      if (!this.wemloNumber) {
        this.$snotify.error(
          "You have not been assigned wemlo number yet.Please contact admin."
        );
        return;
      }
      this.callingStatus = "Dialing...";
      let tempCallId = Math.floor(Math.random() * 100000000000000000);
      var params = {
        wemloNumber: this.wemloNumber,
        tempCallId: tempCallId,
        phoneNumber: this.communicationNumber,
        fromUserId: this.$userId,
        fromType: "Wemlo Processor",
        toUserType: this.communicationUserType,
        toUserId: this.communicationUserId,
        loanTxnId: this.communicationLoanTxnId,
        responseFor: this.commModuleId
      };
      await Device.connect(params);
      await Device.on("connect", connection => {
        this.callInProgress = true;
        this.callingStatus = "In call with " + connection.message.phoneNumber;
      });

      await Device.on("error", e => {
        this.callingStatus = "Cannot connect now.";
        this.callInProgress = false;
      });
      await Device.on("disconnect", async d => {
        this.isReplied = true;
        this.callingStatus = "Disconnected";
        this.callInProgress = false;
        setTimeout(() => {
          this.callingStatus = "Ready";
        }, 200);
        await this.saveAndGetCallDetails(tempCallId);
      });
    } catch (error) {
      console.log(error.response);
    }
  }

  public async saveAndGetCallDetails(tempCallId) {
    try {
      let response = await Axios.post(
        BASE_API_URL + "communication/saveAndGetCallDetails",
        {
          tempCallId: tempCallId,
          fromUserId: this.$userId,
          fromType: "Wemlo Processor",
          toUserType: this.communicationUserType,
          toUserId: this.communicationUserId,
          loanTxnId: this.communicationLoanTxnId
        });
    } catch (error) {
      console.log(error.response);
    }
  }

  public async disconnectCall() {
    try {
      Device.disconnectAll();
    } catch (error) {
      console.log(error.response);
    }
  }

  public async sendTextMessage() {
    try {
      if (!this.wemloNumber) {
        this.$snotify.error(
          "You have not been assigned wemlo number yet.Please contact admin."
        );
        return;
      }
      if (this.message == null) {
        this.$snotify.error("Message body cannot be empty.");
        return;
      }
      this.inProcess = true;
      let toUsers = [
        {
          number: this.communicationNumber,
          userId: this.communicationUserId,
          type: this.communicationUserType
        }
      ];
      let response = await Axios.post(
        BASE_API_URL + "communication/sendTextMessage",
        {
          message: this.message,
          fromUserId: this.$userId,
          fromType: "Wemlo Processor",
          toUsers: toUsers,
          loanTxnId: this.communicationLoanTxnId,
          wemloNumber: this.wemloNumber,
          notificatioModuleId: this.notificatioModuleId
        });
      this.message = null;
      this.isReplied = true;
      this.$snotify.success("Message successfully sent.");
      this.inProcess = false;
    } catch (error) {
      this.inProcess = false;
    }
  }

  public async addMessage() {
    let valid = await this.$validator.validate("message", this.message);
    this.inProcessing = true;
    if (valid) {
      let msg: any = await this.sendMessage({
        toUserType: this.communicationUserType,
        toUserId: this.communicationUserId,
        loanTxnId: this.communicationLoanTxnId,
        isCalledFromDashboard: true,
        notificatioModuleId: this.notificatioModuleId,
        borrowerName: this.currentBorrowerName
      });
      this.isReplied = true;
      this.$snotify.success("Message sent successfully.");
      this.inProcessing = false;
    } else {
      this.showMessageError = true;
      this.inProcessing = false;
      setTimeout(() => {
        this.showMessageError = false;
      }, 1500);
    }
  }

  public async getBorrower(loadMore, sorting = null, descending = null) {
    loadMore ? (this.loadMoreScroll = true) : (this.borrowerLoader = true);
    try {
      if (!loadMore) {
        await this.fetchProcessorsReports(
          this.currentReportTab ? this.currentReportTab : "Closing",
          true
        );
      }
      let data: any = {
        userId: this.$userId,
        searchString: this.searchString ? this.searchString : null,
        isPagination: this.pagination,
        sorting,
        descending
      };
      if (this.searchLoanProcessors != "All") {
        data.specificUser = this.searchLoanProcessors;
      }
      const response = await Axios.post(
        BASE_API_URL + "wemloprocessor/fetchProcessorDashBoard",
        data);
      this.pagination = response.data.pagination;
      this.selectedProcessor = response.data.selectedProcessor;
      this.hideLoadMore = response.data.pipeline.length == 0 || response.data.pipeline.length < this.pagination.end - this.pagination.start;

      this.pipeLineData = loadMore ? this.pipeLineData.concat(response.data.pipeline) : response.data.pipeline;
      this.$store.dispatch("updatePipelineFilters", { searchString: this.searchString, searchLoanProcessors: this.searchLoanProcessors });
    } catch (error) {
      console.log(error);
    } finally {
      this[loadMore ? 'loadMoreScroll' : 'borrowerLoader'] = false
    }
  }

  public getBorrowerLoanType(loanProperty) {
    return loanProperty?.mortgageAppliedFor?.name || '-'
  }
  public async getInprogressLoan(borrowers) {
    borrowers = borrowers.filter(a => {
      if (a.loanStatus[0].statusId != 1 && a.loanStatus[0].statusId != 2) {
        if (a.user.userActive) {
          return a;
        }
      }
    });
    borrowers = JSON.parse(JSON.stringify(borrowers));
    this.borrowers = borrowers;
    this.borrowerList = borrowers;
  }
  public getAssignLoan(loanAssigned, loanTxnId) {
    try {
      let customTask = "";
      if (loanAssigned.length > 0) {
        loanAssigned.forEach(element => {
          if (element.loanTxnId == loanTxnId) {
            customTask = element.customTask;
          }
        });
      }
      return customTask;
    } catch (error) {
      console.log(error);
    }
  }

  public customModal(loanTxnId, additionalDataOfCustomTask, processorId) {
    try {
      if (additionalDataOfCustomTask.completedBy) {
        this.selectedPipelineProcessorId = processorId;
        this.loanTxnId = loanTxnId;
        let samePersonCompleted =
          this.processorId == additionalDataOfCustomTask.completedBy;

        if (!samePersonCompleted) {
          this.completedByName = additionalDataOfCustomTask.completedByName;
        } else {
          this.completedByName = "you";
        }
        this.customId = additionalDataOfCustomTask._id;
        this.$modal.show("customTaskInformation");
      }
    } catch (error) {
      console.log(error);
    }
  }
  public closeCustomTaskModal() {
    this.$modal.hide("customTaskInformation");
    this.completedByName = "";
    this.customId = null;
    this.selectedPipelineProcessorId = null;
  }
  public async removeFromPipeline() {
    try {
      let data = {
        processorId: this.selectedPipelineProcessorId,
        loanTxnId: this.loanTxnId,
        customId: this.customId
      };
      let response = await Axios.post(
        BASE_API_URL + "additionalTask/statusChangedToCustomTask",
        data,
        {
        }
      );

      this.$snotify.success("Task is cleared");
      this.$modal.hide("customTaskInformation");
      this.pagination = JSON.parse(JSON.stringify(this.initialPagination));
      this.getBorrowers();
      this.getBorrower(false);
    } catch (error) {
      console.log(error);
    }
  }

  public async fetchNotificationsBasedOnLoanTxnId() {
    try {
      let response = await Axios.post(
        BASE_API_URL + "wemloprocessor/checkNotificationCount/",
        {
          loanTxnId: this.currentReviewLoanId,
          userId: this.$userId
        });
      let length = response.data.length;
      this.pipeLineData.forEach((data, index) => {
        if (data.loanTxnId == this.currentReviewLoanId) {
          data.notifications = length;
          this["borrowerCommunication"]["borrower" + index] = false;
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  public fileExtension(fileName) {
    let ext = fileName.substr(fileName.lastIndexOf(".") + 1);
    return ext.toLowerCase();
  }

  public async fetchDocuments(docId, loanTxnId) {
    try {
      this.currentReviewLoanId = loanTxnId;
      let data = { loanTxnId: loanTxnId };
      let response = await Axios.post(
        BASE_API_URL + "wemloProcessor/fetchDocuments",
        data);
      this.documents = response.data.docs;
      this.comments = response.data.comments;
      response.data.docs.forEach(doc => {
        if (doc._id == docId) {
          this.$refs.df["setInitialValues"](
            this.documents,
            this.comments,
            doc,
            true,
            loanTxnId
          );
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  public getTrimmedMessageBody(message) {
    let msg = striptags(message, [], " "); //To strip html into normal text.
    msg = msg.replace(/\s\s+/g, " "); //To remove multiple white spaces as above function create multiple white spaces in case of <ol> and <ul> tags.
    msg = msg.replace(/&amp;/g, "&"); //To handle ampersand case
    if (msg.length > 25) {
      return msg.slice(0, 24) + "...";
    } else {
      return msg;
    }
  }

  public checkOrderTitle(borrower) {
    try {
      let returnData;
      let check = borrower.titleInformation;
      let tasks = borrower.tasks;
      tasks.forEach(element => {
        if (
          element.tasks.orderTitle &&
          element.tasks.orderTitle.isActiveOrderTitle
        ) {
          returnData = true;
        } else {
          returnData = false;
        }
      });
      return returnData;
    } catch (error) {
      console.log(error);
    }
  }
  get userId() {
    return this.$store.state.sessionObject.userId;
  }
  public async uploadCustomTaskDoc(event) {
    try {
      if (event.target.files.length > 0) {
        Object.values(event.target.files).forEach(f => {
          this.customTaskData.attachments.push(f);
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
  public displayDocuments(files) {
    this.$refs.df["setInitialValuesForAttachmentsAndComments"](
      null,
      files,
      null,
      {
        loanTxnId: this.communicationLoanTxnId,
        commId: this.notificatioModuleId
      }
    );
  }
  public hideCustomTask() {
    this.$modal.hide("customTask");
    this.customTaskData = new CustomTask();
  }
  public async submitCustomTask(ifSave) {
    try {
      if (!(await this.$validator.validateAll())) return;

      this.$store.state.wemloLoader = true;
      let formData = new FormData();

      if (this.customTaskData.attachments.length > 0) {
        this.customTaskData.attachments.forEach(f => {
          formData.append("file", f);
        });
      }
      formData.append("customTaskData", JSON.stringify(this.customTaskData));
      formData.append("SavingTask", "saving");
      let response = await Axios.post(
        BASE_API_URL + "additionalTask/submitCustomTask",
        formData);
      this.$store.state.wemloLoader = false;
      this.$modal.hide("customTask");
      this.$snotify[
        response.data.status == 200
          ? "success"
          : response.data.status == 403
          ? "info"
          : "error"
      ](response.data.message);
      this.pagination = JSON.parse(JSON.stringify(this.initialPagination));
      this.customTaskData = new CustomTask();
      this.getBorrower(false);
    } catch (error) {
      console.log(error);
      this.$store.state.wemloLoader = false;
    }
  }
  public displayCustomTaskDocument(files) {
    this.$refs.df["setInitialValuesForLocalFiles"](files, true, null);
  }
  public deleteDoc(obj) {
    if (!obj.deleteData) this.customTaskData.attachments.splice(obj.index, 1);
    else this[obj.deleteData].splice(obj.index, 1);
  }
  // public async submitCustomTask() {
  //   try {
  //     this.disabledButton = true;
  //     let formValid = await this.$validator.validateAll();
  //     if (!formValid) {
  //       this.closingError = true;
  //       this.disabledButton = false;
  //       return;
  //     }
  //     let time =
  //       this.time.dayNight == "PM" ? 12 + this.time.hour : this.time.hour;
  //     this.customTask.time =
  //       time + ":" + this.time.minutes + " " + this.time.dayNight;
  //     let data = {
  //       data: this.customTask,
  //       userId: this.userId,
  //       attachment: this.files,
  //       identifier: "Add Task"
  //     };
  //     let formData = new FormData();
  //     this.files.forEach((f: any) => {
  //       formData.append("file", f);
  //     });

  //     formData.append("data", JSON.stringify(data));
  //     let response = await Axios.post(
  //       BASE_API_URL + "additionalTask/submitCustomTask",
  //       formData,
  ////     );
  //     this.$modal.hide("customTask");
  //     this.$snotify.success("Task saved successfully");
  //     this.disabledButton = false;
  //     this.pagination = JSON.parse(JSON.stringify(this.initialPagination));
  //     this.getBorrower(false);
  //     this.customTask = {
  //       taskName: null,
  //       type: null,
  //       teamMember: null,
  //       borrower: null,
  //       displayEventToBorrower: false,
  //       selectDate: null,
  //       time: null,
  //       reminder: null,
  //       description: null
  //     };
  //     this.time = {
  //       hour: null,
  //       dayNight: "AM",
  //       minutes: null
  //     };
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }
  // public downloadFile(file) {
  //   window.open(file);
  // }

  get twilioActiveStatus() {
    this.callingStatus = this.$store.state.twilioActiveStatus;
    return this.$store.state.twilioActiveStatus;
  }

  getUnreadedCommunications(communication) {
    let unReadedComm = communication.filter(e => e.isRead == false);
    return unReadedComm.length > 0 ? unReadedComm.length : "-";
  }
  public disablingToDate() {
    this.closingError = false;
    let fromDate = new Date(this.customTask.selectDate);
    this.state.disabledDatesTo.to = fromDate;
  }

  //For viewing the document for email attachment
  public async viewAttachments(data) {
    this.$refs.df["setInitialValuesForAttachmentsAndComments"](null, data, {
      loanTxnId: this.communicationLoanTxnId,
      commId: this.notificatioModuleId
    });
  }
  public async searchByBorrowerName(event) {
    this.getAllBorrowers(event);
  }

  public async getAllBorrowers(searchByName) {
    this.dotLoader = true;
    try {
      let response = await Axios.post(
        BASE_API_URL + "common/getAllBorrowers",
        {
          searchByName: searchByName ? searchByName : ""
        });
      this.borrowerName = response.data;
      this.dotLoader = false;
    } catch (error) {
      this.dotLoader = false;
      console.log(error.response);
    }
  }
  public async getNotifyOfCustomTask() {
    try {
      let response = await Axios.post(
        BASE_API_URL + "common/getNotifyOfCustomTask",
        { processorId: this.$userId });
      if (response.data.messageForProcessor.length > 0) {
        this.$snotify.clear();
        response.data.messageForProcessor.forEach((element, i) => {
          let snotifyDesign = `<div class="wnotifyToast__inner">
          <div class="wnotifyToast__title">New Notification</div> 
          <button type="button" class="wnotifyToast__close " data-dismiss="modal" @click=""$snotify.remove(${i})>
            <div class="wnotify-close"></div>
          </button>
          <div class="wnotifyToast__inner">
          <div class="wnotifyToast__body">
            <div class="wnotifyToast__msg-box">
              <div class="wnotify-icon wnotify-icon--default"></div>
              <div class="wnotifyToast__body--msg"> ${element.message}</div>
              <div class="wnotifyToast__buttons">
                <button type="button" class="btn-wemlo btn-wemlo__info" @click="$snotify.remove(${i})"> OK </button>
              </div>
            </div></div>
          </div>
        </div>`;
          let position: any = "rightTop";
          this.$snotify.simple(null, {
            html: snotifyDesign,
            showProgressBar: false,
            timeout: 500000,
            position: position
          });
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  /**************************************************************************************************
   *                                   FOR FETCHING THE REPORTS OF PROCESSORS                        *
   ***************************************************************************************************
   * Function Working process -----------------------------------------------------------------------
   * Send the Report Category in request's body to server,
   * then get the reponse from server which is all reports in array.
   */
  public async fetchProcessorsReports(category, mountCall: boolean = false) {
    try {
      this.currentReportTab = category;
      this.page = mountCall ? 0 : this.page + 10;
      mountCall
        ? (this.reportLoader = true)
        : (this.reportCategoryLoader = true);
      let data = null;

      let response = await Axios.post(
        BASE_API_URL + "wemloprocessor/fetchProcessorReports",
        {
          specificProcessorId: this.searchLoanProcessors,
          reportCategory: this.currentReportTab,
          data,
          options: {
            page: this.page,
            limit: 10,
          }
        });
      if (mountCall) {
        this.reportLoader = false;
        this.reports = response.data.reports;
      } else {
        this.reportCategoryLoader = false;
        this.reports = this.reports.concat(response.data.reports);
      }
      this.reportCategoryCount.critical =
        response.data.reportCategoryCount.critical;
      this.reportCategoryCount.warning =
        response.data.reportCategoryCount.warning;
      this.reportCounts = response.data.reportCounts;
    } catch (error) {
      mountCall
        ? (this.reportLoader = false)
        : (this.reportCategoryLoader = false);
      console.log(error);
    }
  }

  /**************************************************************************************************
   *                                   FOR CLEARING THE REPORTS                                      *
   ***************************************************************************************************
   * Function Working process -----------------------------------------------------------------------
   * Send the Report _id with enetered password in request's body to server,
   * if the password is correct then the message will print report clear,
   * and report will be dismissed from dashboard .
   */
  public async clearReport() {
    this.clearReportLoader = true;
    try {
      let valid = await this.$validator.validateAll();
      if (!valid) {
        this.clearReportLoader = false;
        return;
      }
      let response = await Axios.post(
        BASE_API_URL + "wemloprocessor/clearReport",
        this.clearReportData);
      if (response.data.status == 200) {
        let report = this.reports.find(
          r => r._id == this.clearReportData.reportId
        );
        this.reports = this.reports.filter(
          r => r._id != this.clearReportData.reportId
        );
        this.reportCategoryCount[report.priority.toLowerCase()]--;
        this.reportCounts[report.reportCategory.toLowerCase()]--;
        this.$snotify.success(response.data.message);
        this.closeClearReportModal();
      } else if (response.data.status == 403) {
        this.$snotify.error(response.data.message);
      }
      this.clearReportLoader = false;
    } catch (error) {
      this.clearReportLoader = false;
      console.log(error);
    }
  }

  /**************************************************************************************************
   *                                FOR SHOWING THE MODAL OF CLEAR REPORT                            *
   ***************************************************************************************************
   * Function Working process -----------------------------------------------------------------------
   * Called from HTML when click the clear button
   * in reports section. It just clear the password field &
   * set the report._id in @var {clearReportData}
   * and shows the  clearReport named modal.
   */
  public clickClearButton(reportId) {
    this.clearReportData.password = null;
    this.clearReportData.reportId = reportId;
    this.$modal.show("clearReport");
  }

  /**
   * Just for clearing password & comment which user inputs and closing modal of clear report.
   */
  public closeClearReportModal() {
    this.$modal.hide("clearReport");
    this.clearReportData = {
      password: null,
      reportId: null,
      clearComment: null
    };
  }

  public async fetchCommunicationDetailsForReports(reportId) {
    this.$store.state.wemloLoader = true;
    try {
      let response = await Axios.post(
        BASE_API_URL + "wemloprocessor/fetchCommunicationDetailsForReports",
        { reportId: reportId });
      this.$modal.show("replyCommunicationFromReports");
      this.replyCommunicationData = response.data;
      this.$store.state.wemloLoader = false;
    } catch (error) {
      this.$store.state.wemloLoader = false;
      console.log(error);
    }
  }

  public closeReplyModalForReoprtCommunication() {
    this.$modal.hide("replyCommunicationFromReports");
    this.replyCommunicationData = new replyCommunicationData();
    this.replyCommunicationMessage = new replyCommunicationMessage();
  }

  public uploadCommunicationAttachment(event) {
    try {
      if (event.target.files.length > 0) {
        this.replyCommunicationMessage.attachments = [];
        Object.values(event.target.files).forEach(f => {
          this.replyCommunicationMessage.attachments.push(f);
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async replyCommunicationViaText() {
    this.replyCommunicationLoader.text = true;
    try {
      let valid = await this.$validator.validate("replyCommunicationMessage");
      if (!valid) {
        this.replyCommunicationLoader.text = false;
        return;
      }
      if (!this.wemloNumber) {
        this.$snotify.error(
          "You have not been assigned wemlo number yet. Please contact admin."
        );
        this.replyCommunicationLoader.text = false;
        return;
      }
      let data: any = {
        message: this.replyCommunicationMessage.message,
        fromUserId: this.$userId,
        fromType: "Wemlo Processor",
        toUsers: [
          {
            number:
              PHONE_EXT +
              this.replyCommunicationData.toUser[0].number.replace(
                /[()-\s]/g,
                ""
              ),
            userId: this.replyCommunicationData.toUser[0].userId,
            type: this.replyCommunicationData.toUser[0].type
          }
        ],
        loanTxnId: this.replyCommunicationData.loanTxnId,
        wemloNumber: this.wemloNumber
      };
      if (this.replyCommunicationData.communication) {
        data.notificatioModuleId = this.replyCommunicationData.communication.responseFor;
      }
      let response = await Axios.post(
        BASE_API_URL + "communication/sendTextMessage",
        data);
      this.closeReplyModalForReoprtCommunication();
      this.fetchProcessorsReports("Communication", true);
      this.$snotify.success("Text sent successfully");
      this.replyCommunicationLoader.text = false;
    } catch (error) {
      this.replyCommunicationLoader.text = false;
      console.log(error);
    }
  }

  public async replyCommunicationViaMessage() {
    this.replyCommunicationLoader.message = true;
    try {
      let valid = await this.$validator.validate("replyCommunicationMessage");
      if (!valid) {
        this.replyCommunicationLoader.message = false;
        return;
      }
      let data: any = {
        message: this.replyCommunicationMessage.message,
        file: this.replyCommunicationMessage.attachments,
        toUserType: this.replyCommunicationData.toUser[0].type,
        toUserId: this.replyCommunicationData.toUser[0].userId,
        loanTxnId: this.replyCommunicationData.loanTxnId,
        isCalledFromDashboard: true,
        borrowerName: this.replyCommunicationData.borrowerName
      };
      if (this.replyCommunicationData.communication) {
        data.notificatioModuleId = this.replyCommunicationData.communication.responseFor;
      }
      await this.sendMessage(data);
      this.closeReplyModalForReoprtCommunication();
      this.fetchProcessorsReports("Communication", true);
      this.replyCommunicationLoader.message = false;
      this.$snotify.success("Message sent successfully");
    } catch (error) {
      this.replyCommunicationLoader.message = false;
      console.log(error);
    }
  }
  public stripAndSlice(data) {
    return striptags(data).slice(0, 50) + "...";
  }
  public async uploadSample(event) {
    try {
      if (event.target.files.length == 0) {
        return;
      }
      this.$store.state.wemloLoader = true;
      this.file = [];
      Object.values(event.target.files).forEach(f => {
        this.file.push(f);
      });
      // let path = "Borrower/" + this.$route.query.id + "/LoanDocuments/";
      // let formData = new FormData();
      // formData.append("path", JSON.stringify(path));
      // this.file.forEach(f => {
      //   formData.append("file", f);
      // });
      // let response = await Axios.post(
      //   BASE_API_URL + "wemloprocessor/getFileUploadedPath",
      //   formData,
      //// );
      // response.data.forEach(element => {
      //   this.commentSampleAttachment.push({ path: element.path });
      // });
      this.$store.state.wemloLoader = false;
    } catch (error) {
      this.$store.state.wemloLoader = false;
      console.log(error);
    }
  }

  public async editLockDate(data, type) {
    try {
      this.showClosingandRateDate = true;
      this.updateDatesObj = {
        fieldName: type,
        borrower: data
      };
    } catch (error) {
      console.log(error);
    }
  }
  public async submitUpdatedData() {
    try {
      let formValid = await this.$validator.validateAll();
      if (!formValid) {
        return;
      }
      if (this.taskOption.lockType == "Float Rate") {
        this.taskOption.extendedDate = null;
        this.taskOption.updatedRateExpirationDate = null;
        this.taskOption.rateLockDate = null;
      }
      //**********************************************
      if (this.editType == "closing") {
        this.loanAudit.activity.closingDate = this.taskOption.closingDate;
        this.loanAudit.loanTxnId = this.taskOption.loanTxnId;
        (this.taskOption.lockType = false),
          (this.taskOption.rateLockDate = null),
          (this.taskOption.updatedRateExpirationDate = null),
          (this.taskOption.extendedDate = null),
          (this.taskOption.extensionType = false);
      } else {
        this.loanAudit.loanTxnId = this.taskOption.loanTxnId;
        this.loanAudit.activity.rateType = this.taskOption.lockType;
        this.loanAudit.activity.rateLockExpirationDate = this.taskOption.updatedRateExpirationDate;
        this.loanAudit.activity.rateLockDate = this.taskOption.rateLockDate;
        this.loanAudit.activity.extendedDate = this.taskOption.extendedDate;
        this.taskOption.closingDate = null;
      }

      //**********************************************
      let response = await Axios.post(
        BASE_API_URL + "wemloprocessor/editLockDate",
        {
          taskOption: this.taskOption,
          loanAudit: this.loanAudit,
          type: this.editType
        });
      this.$snotify.success("Saved successfully");
      this.hideModel();
      this.pagination = JSON.parse(JSON.stringify(this.initialPagination));
      this.getBorrower(false);
      this.fetchProcessorsReports(this.currentReportTab, true);
    } catch (error) {}
  }
  public hideModel() {
    this.$modal.hide("editLockDate");
    this.showLockRateDate = false;
    this.taskOption.lockType = false;
    this.taskOption.rateLockDate = null;
    this.taskOption.updatedRateExpirationDate = null;
    this.taskOption.extendedDate = null;
    this.taskOption.loanTxnId = null;
    this.taskOption.extensionType = false;
    this.restoreDisableDates();
  }
  public restoreDisableDates() {
    let date = new Date().setDate(new Date().getDate() - 1);
    this.state = {
      disabledDatesFrom: {
        to: new Date(date)
      },
      disabledDatesFromOne: {
        to: new Date(date)
      },
      disabledDatesFromTwo: {
        to: new Date(date)
      },
      disabledDatesTo: {
        to: new Date(date)
      }
    };
  }

  async showProcessorsHistory(loanTxnId) {
    try {
      this.$store.state.wemloLoader = true;
      let response = await Axios.post(
        BASE_API_URL + "wemloprocessor/fetchProcessorsHistory",
        { loanTxnId: loanTxnId });
      if (response.data.status == 200) {
        this.processorsHistory = response.data.list;
        this.$modal.show("showProcessorsHistory");
      }
      this.$store.state.wemloLoader = false;
    } catch (error) {
      this.$store.state.wemloLoader = false;
      console.log(error);
    }
  }

  cleanFilters () {
    this.pagination = {"start":0,"end":15};
    this.searchString = '';
    this.searchLoanProcessors = 'All';
    this.$store.dispatch("updatePipelineFilters", { searchString: this.searchString, searchLoanProcessors: this.searchLoanProcessors });
    this.getBorrower(false);
  }

  async mounted() {
    this.searchString = this.getGlobalPipelineFilters.searchString;
    this.searchLoanProcessors = this.getGlobalPipelineFilters.searchLoanProcessors;
    this.getAllStatusCount(true);
    this.getProcessorLoanReports(true);
    this.getWemloStaffList();
    this.getNotifyOfCustomTask();
    this.callingStatus = this.twilioActiveStatus;
    this.getBorrower(false);
    if (this.$loader) {
      this.$loader.hide();
    }

    Device.on("ready", a => {
      this.$store.dispatch("setTwilioActiveStatus", "Ready");
    });
    Device.on("error", e => {
      this.$store.dispatch("setTwilioActiveStatus", "Cannot connect now.");
    });
    window.addEventListener("beforeunload", function(e) {
      let data = JSON.parse(localStorage["state.WeMLO"]);
      if (data.removeLocalFiles.length > 0) {
        store.dispatch("removeLocalFiles", data.removeLocalFiles);
      }
    });
  }

  /**************************************************************************************************
   *                   FIRED WHEN FILE GET SELECTED FROM REPLY MODAL                                 *
   ***************************************************************************************************
   * -------FUNCTION WORKING PROCESS-----------------------------------------------------------------
   * Gets @param { event } from parameters
   * then merge it to this.file variable which is available in
   * common mixins then submit the message from @function sendMessage
   * which is also present in common mixins.
   */
  public uploadMessageAttachment(event) {
    try {
      if (event.target.files.length > 0) {
        this.file = [];
        Object.values(event.target.files).forEach(element => {
          this.file.push(element);
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  /**************************************************************************************************
   *             WHEN CLICK ON WHITE TEXT SHOWING FILES NUMBER ON REPLY MESSAGE MODAL                *
   ***************************************************************************************************
   * FUNCTION WORKING PROCESS -----------------------------------------------------------------------
   * Getting files which are present in @var { file }
   * and sent it to common function to display.
   */
  public showCommunicationAttachment(file, ifDelete, path) {
    try {
      this.$refs.df["setInitialValuesForLocalFiles"](file, ifDelete, path);
    } catch (error) {
      console.log(error);
    }
  }

  public getTimeDifference(date, timeFormat) {
    let d1 = moment(date);
    let d2 = moment(new Date());
    if (d1 > d2) {
      d2 = d1;
      d1 = moment(new Date());
    }
    let duration = moment.duration(d2.diff(d1));
    return (
      Math.round(duration[timeFormat == "day" ? "asDays" : "asHours"]()) +
      " " +
      timeFormat +
      "s"
    );
  }

  /**************************************************************************************************
   *                                    IF FILES NOT LOADED IN DATABASE                              *
   **************************************************************************************************/
  async beforeDestroy() {
    this.$snotify.clear();
    Device.destroy();
    let data = JSON.parse(localStorage["state.WeMLO"]);
    if (data.removeLocalFiles.length > 0) {
      this.$store.dispatch("removeLocalFiles", data.removeLocalFiles);
    }
  }

  public async showSorting(sortingName) {
    try {
      if(this.selectedSorting == sortingName) return this.getSort();
      this.selectedSorting = sortingName;
      this.sortDESC = false;
      delete this.pagination;
      await this.getBorrower(false, this.selectedSorting, this.sortDESC);
    } catch (error) {
      console.log(error);
    }
  }

  public isSorting(sortingName) {
    return this.selectedSorting == sortingName;
  }
  
  public async getSort() {
    this.sortDESC = !this.sortDESC;
    delete this.pagination;
    await this.getBorrower(false, this.selectedSorting, this.sortDESC);
  }
}

/**
 * Model for class fetching data for whom to reply
 */
class replyCommunicationData {
  constructor() {
    this.communication = new communicationObj();
    this.toUser = new Array<toUser>();
  }
  public loanTxnId: String;
  public borrowerName: String;
  public toUser: Array<toUser>;
  public communication: communicationObj;
}

class toUser {
  public type: String;
  public userId: String;
  public name: String;
  public number: String;
}

class communicationObj {
  constructor() {
    this.attachments = [];
  }
  public attachments: Array<any>;
  public body: String;
  public responseFor: String;
}

/**
 * Class for replying communication from reports
 */
class replyCommunicationMessage {
  constructor() {
    this.attachments = [];
  }
  public message: String;
  public attachments: Array<any>;
}
